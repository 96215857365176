import { NgModule } from '@angular/core';
import { Routes, RouterModule , PreloadAllModules } from '@angular/router';
// import { MainLayoutComponent } from './shared/components/main-layout/main-layout.component';
// import { AuthGuard } from './auth/guards/auth.guard';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: '', /* canActivate: [AuthGuard], */
    component: AppComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        loadChildren: () => import( './home/home.module').then( m => m.HomeModule )
      },
      {
        path:'terminos',
        loadChildren: () => import( './terminos/terminos.module').then( m => m.TerminosModule )
      },
      {
        path:'politicas',
        loadChildren: () => import( './politicas/politicas.module').then( m => m.PoliticasModule )
      }
    ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
