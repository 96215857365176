// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase : {
    apiKey: 'AIzaSyCtOhrYDqmbWnMjnHDGWjS72uQrY224RQ8',
    authDomain: 'dev-catchme.firebaseapp.com',
    databaseURL: 'https://dev-catchme.firebaseio.com',
    projectId: 'dev-catchme',
    storageBucket: 'dev-catchme.appspot.com',
    messagingSenderId: '750233393637',
    appId: '1:750233393637:web:f85d1811117da58c7d770c'
  },
  hereApi: 'R9gIFEvHeWkc44031D5K'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
